export default function PartsRecievedHeader() {
  return (
    <div className={"parts-recieved__header"}>
      <div>Ημερομηνια</div>
      <div>Π αρ</div>
      <div>Χορηγησεις</div>
      <div>Αριθμός εργασιας</div>
      <div>Παρατηρησεις</div>
      <div>Ημερομηνια Εξ</div>
      <div>Ποσοτητηα Εξ</div>
      <div></div>
    </div>
  );
}
